var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "secondary my-4" },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "white--text text-h4" },
                        [_vm._v(" AAE Minutes ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mx-3" }, [
                    _c(
                      "div",
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Company Name",
                            dense: "",
                            outlined: "",
                            filled: "",
                            disabled: "",
                          },
                          model: {
                            value: _vm.companyName,
                            callback: function ($$v) {
                              _vm.companyName = $$v
                            },
                            expression: "companyName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Current Minutes",
                            dense: "",
                            outlined: "",
                            filled: "",
                            disabled: "",
                          },
                          model: {
                            value: _vm.currentMinutes,
                            callback: function ($$v) {
                              _vm.currentMinutes = $$v
                            },
                            expression: "currentMinutes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            rules: _vm.rules.min,
                            min: "0",
                            max: "100",
                            type: "number",
                            suffix: "minutes",
                            placeholder: "Add minutes...",
                          },
                          model: {
                            value: _vm.additionalMinutes,
                            callback: function ($$v) {
                              _vm.additionalMinutes = $$v
                            },
                            expression: "additionalMinutes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("v-textarea", {
                          attrs: {
                            filled: "",
                            solo: "",
                            dense: "",
                            label: "Remark...",
                          },
                          model: {
                            value: _vm.remark,
                            callback: function ($$v) {
                              _vm.remark = $$v
                            },
                            expression: "remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", plain: "" },
                            on: { click: _vm.redirectBack },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            attrs: { loading: _vm.api.isLoading },
                            on: { click: _vm.validateInput },
                          },
                          [_vm._v(" Confirm ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }